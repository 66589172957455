import {
	AnalysisGroup,
	AnalysisSetting,
	AnalysisSettingsValue,
	CalculationBaseGroup,
	ConstructiveSales,
	DataTypes,
	Dividend,
	Hedging,
	Straddles,
} from './Settings.types';

export class CalculationSetting {
	buildWashSalesAnalysisSettingValue = (
		washSalesAnalysis: string[],
		analysisSetting: AnalysisSetting[],
		overrideWashSalesFactors: boolean,
	): string => {
		const analysisSubSettingValues: AnalysisSettingsValue[] = [
			{
				analysisSettingId: analysisSetting.filter((i) => i.analysisGroup === AnalysisGroup.WashSales)[0]
					.analysisSettingID,
				settingValueType: DataTypes.Boolean,
				settingValues: [`${overrideWashSalesFactors}`],
			},
		];

		return this.buildSettings(washSalesAnalysis, analysisSubSettingValues);
	};

	buildConstructiveSalesAnalysisSettingValue = (
		analysis: string[],
		analysisSetting: AnalysisSetting[],
		reliefMethodology: string,
		forcedConstructiveSales: string,
	): string => {
		const analysisSettingID = analysisSetting.filter((i) => i.analysisGroup === AnalysisGroup.ConstructiveSales)[0]
			.analysisSettingID;
		const analysisSubSettingValues: AnalysisSettingsValue[] = [
			{
				analysisSettingId: analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${reliefMethodology}`],
				type: ConstructiveSales.ReliefMethodology,
			},
			{
				analysisSettingId: analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${forcedConstructiveSales}`],
				type: ConstructiveSales.ForcedConstructiveSales,
			},
		];

		return this.buildSettings(analysis, analysisSubSettingValues);
	};

	buildDividendAnalysisSettingValue = (
		dividendAnalysis: string[],
		analysisSetting: AnalysisSetting[],
		overrideDividendFactors: boolean,
		accrualBasis: string
	): string => {
		const analysisId = analysisSetting.filter((i) => i.analysisGroup === AnalysisGroup.Dividend);
		if(analysisId.length === 0){
			return '';
		}
		const analysisSubSettingValues: AnalysisSettingsValue[] = [
			{
				analysisSettingId: analysisId[0].analysisSettingID,
				settingValueType: DataTypes.Boolean,
				settingValues: [`${overrideDividendFactors}`],
				type: Dividend.OverridDividendFactors,
			}
		];

		return this.buildSettings(dividendAnalysis, analysisSubSettingValues);
	};

	buildCharacterAnalysisSettingValue = (
		analysis: string[],
		analysisSetting: AnalysisSetting[],
		character: string,
	): string => {
		const analysisSubSettingValues: AnalysisSettingsValue[] = [
			{
				analysisSettingId: analysisSetting.filter((i) => i.analysisGroup === AnalysisGroup.Character)[0]
					.analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${character}`],
			},
		];

		return this.buildSettings(analysis, analysisSubSettingValues);
	};

	buildStraddlesAnalysisSettingValue = (
		analysis: string[],
		analysisSetting: AnalysisSetting[],
		straddleSetting: string,
		offsetRelief: string,
		realizedRelief: string,
	): string => {
		const analysisSettingID = analysisSetting.filter((i) => i.analysisGroup === AnalysisGroup.Straddles)[0]
			.analysisSettingID;
		const analysisSubSettingValues: AnalysisSettingsValue[] = [
			{
				analysisSettingId: analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${straddleSetting}`],
				type: Straddles.Hedging,
			},
			{
				analysisSettingId: analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${offsetRelief}`],
				type: Straddles.OffsetRelief,
			},
		];
		if (straddleSetting === Hedging.OneToAll) {
			analysisSubSettingValues.push({
				analysisSettingId: analysisSettingID,
				settingValueType: DataTypes.String,
				settingValues: [`${realizedRelief}`],
				type: Straddles.RealizedRelief,
			});
		}
		return this.buildSettings(analysis, analysisSubSettingValues);
	};

	buildPaymentAnalysisSettingValue = (paymentAnalysis: string[]): string => {
		return this.buildSettings(paymentAnalysis, []);
	};

	buildCalculationAnalysis = (
		clientType: string,
		periodStartDate: Date,
		periodEndDate: Date,
		reporting: string,
		bifurcation: string
	): string => {
		let analysisSubSettingValues: AnalysisSettingsValue[] = [];
		const calculationSettingID = 'baseCalcSettings';

		analysisSubSettingValues.push(
			this.buildAnalysisSettingValueCalculation(
				CalculationBaseGroup.PeriodFrom,
				DataTypes.Date,
				[periodStartDate.toLocaleString()],
				calculationSettingID,
			),
			this.buildAnalysisSettingValueCalculation(
				CalculationBaseGroup.PeriodTo,
				DataTypes.Date,
				[periodEndDate.toLocaleString()],
				calculationSettingID,
			),
			this.buildAnalysisSettingValueCalculation(
				CalculationBaseGroup.ClientType,
				DataTypes.String,
				[`${clientType}`],
				calculationSettingID,
			),
			this.buildAnalysisSettingValueCalculation(
				CalculationBaseGroup.Reporting,
				DataTypes.String,
				[`${reporting}`],
				calculationSettingID,
			),
			this.buildAnalysisSettingValueCalculation(
				CalculationBaseGroup.Bifurcation,
				DataTypes.String,
				[`${bifurcation}`],
				calculationSettingID,
			),
		);
		return JSON.stringify({ analysisSetting: analysisSubSettingValues });
	};

	buildSettings = (analysis: string[], analysisSubSettingValues: AnalysisSettingsValue[]): string => {
		return JSON.stringify({
			analysis: analysis,
			analysisSetting: analysisSubSettingValues,
		});
	};

	buildAnalysisSettingValueCalculation = (
		calculationGroupId: CalculationBaseGroup,
		settingValueType: string,
		settingValues: string[],
		analysisSettingID: string,
	): AnalysisSettingsValue => {
		return {
			type: calculationGroupId,
			analysisSettingId: analysisSettingID,
			settingValueType: settingValueType,
			settingValues: settingValues,
		};
	};
}
