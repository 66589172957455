export enum ApiUrls {
	//calculation
	RUN_CALCULATION_PREDEFINED = 'Calculation/job/{jobId}/start-predefined',
	RUN_CALCULATION_WITH_SETTINGS = 'Calculation/job/{jobId}/start-custom',
	RUN_CALCULATION_MOST_RECENT = 'Calculation/job/{jobId}/most-recent',
	GET_RECENT_CALCULATIONS = 'Calculation/job/{jobId}/recent-runs/{take}',

	//Dashboard
	GET_DASHBOARD_SUMMARY = 'Dashboard/job/{jobId}/summary/{calculationProcessId}',
	GET_PIE_CHARTS_DATA = 'charts/job/{jobId}/pie-chart',
	GET_BAR_CHARTS_DATA = 'charts/job/{jobId}/bar-chart',
	GET_DRILLDOWN_CHARTS_DATA = 'charts/job/{jobId}/drillDown-chart',
	GET_BOOKTAXABLE_CHARTS_DATA = 'charts/job/{jobId}/booktaxable-chart',

	//report
	GET_BOOK_TO_TAX_REPORT_CONTENT = 'report/job/{jobId}/book-to-tax',
	GET_DIVIDEND_REPORT_CONTENT = 'report/job/{jobId}/dividend',
	GET_COMBINED_REPORT_CONTENT = 'report/job/{jobId}/combined',
	DOWNLOAD_REPORT = 'calculation/{calculationId}/report',
	DOWNLOAD_ERROR = 'calculation/{calculationId}/error',
	GET_STRADDLE_REPORT_CONTENT = 'report/job/{jobId}/straddle',

	//Reference Table
	GET_CLIENT_REFERENCE_LIBRARY = 'client/{clientId}/client-reference',
	UPLOAD_REFERENCE_FILE = 'client/{clientId}/client-reference',
	DOWNLOAD_REFERENCE_FILE = 'client/{clientId}/client-reference/{id}/file-download/{type}',
	UPDATE_REFERENCE_FILE = 'client/{clientId}/client-reference/{id}/update',
	RSM_REFERENCE_LIBRARY = 'rsm-standard-reference',
	DOWNLOAD_RSM_REFERENCE_FILE = 'rsm-standard-reference/{id}/download',
	CHECK_CONNECTED_JOBS_FOR_CLIENT_REFERENCE_TABLES = 'client/{clientId}/client-reference/{id}/connected-jobs',

	//Ruleset
	GET_CLIENT_RULESET = 'ruleset/client/{clientId}',
	GET_RSM_RULESET = 'ruleset',
	UPLOAD_CLIENT_RULESET = 'ruleset/client-upload/{clientId}',
	UPLOAD_RSM_RULESET = 'ruleset/upload',
	UPDATE_CLIENT_RULESET = 'ruleset/client/{clientId}/update-metadata',
	UPDATE_RSM_RULESET = 'ruleset/update-metadata',
	CHECK_CONNECTED_JOBS_FOR_RULESET = 'ruleset/{rulesetId}/connected-jobs',

	//Asset warehouse
	GET_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client/{clientId}',
	GET_RSM_ASSETWAREHOUSE = 'asset-warehouse',
	UPLOAD_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client-upload/{clientId}',
	UPLOAD_RSM_ASSETWAREHOUSE = 'asset-warehouse/upload',
	UPDATE_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client/{clientId}/update-metadata',
	UPDATE_RSM_ASSETWAREHOUSE = 'asset-warehouse/update-metadata',

	//Dividend Table
	GET_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client/{clientId}',
	GET_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse',
	UPLOAD_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client-upload/{clientId}',
	UPLOAD_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse/upload',
	DOWNLOAD_DIVIDEND_WAREHOUSE = 'file-download/{fileUploadId}',
	UPDATE_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client/{clientId}/update-metadata',
	UPDATE_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse/update-metadata',

	//Inputs
	GET_DATASET_INPUT_JSON = 'job/{jobId}/dataset/{datasetId}/input/{type}',
	GET_LIBRARY_INPUT_JSON = 'Inputs/{id}/type/{type}',
	GET_CALCULATION_INPUT = 'Dataset/{importId}',

	//Usage Statistics
	GET_IMPORT_STATISTICS = 'importStatistics/{startDate}/{endDate}',
	GET_CALCULATION_STATISTICS = 'calculationStatistics/{startDate}/{endDate}',

	//Job
	BASE_JOB_URL = 'job/{jobId}/',
	CREATE_STATUS_DETAIL = 'detail/job/{jobId}/create',
	GET_STATUS_DETAIL = 'detail/job/{jobId}/most-recent',
	CREATE_EVENT = 'event/job/{jobId}/type/{eventType}',
	GET_EVENTS = 'event/job/{jobId}',
	GET_FAVORITE_JOBS = 'favoriteJobs',
	CREATE_FAVORITE_JOBS = 'favoriteJobs/job/{jobId}',
	REMOVE_FAVORITE_JOBS = 'favoriteJobs/job/{jobId}'
}
