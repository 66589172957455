import { CalculationSetting } from './CalculationSetting';
import {
	AccrualBasis,
	AnalysisGroup,
	AnalysisSettingsResponse, Bifurcation, Character,
	ClientType,
	ForcedConstructiveSales,
	Hedging,
	ReliefMethodOptions,
	Reporting,
} from './Settings.types';

export class DefaultCalculationSetting extends CalculationSetting {
	build = (analysisSettingsResponse: AnalysisSettingsResponse): any => {
		let jsonObject: any = {};

		jsonObject[AnalysisGroup.Calculation] = this.buildCalculationAnalysis(
			this.buildClientType(),
			this.buildStartDate(),
			this.buildEndDate(),
			this.buildReporting(),
			this.buildBifurcation()
		);
		jsonObject[AnalysisGroup.Dividend] = this.buildDividendAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.WashSales] = this.buildWashSalesAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.Payments] = this.buildPaymentsAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.Straddles] = this.buildStraddlesAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.ConstructiveSales] = this.buildConstructiveSalesAnalysis(analysisSettingsResponse);
		jsonObject[AnalysisGroup.Character] = this.buildCharacterAnalysis(analysisSettingsResponse);

		return jsonObject;
	};

	buildDividendAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const dividendAnalysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Dividend,
		);
		const dividendAnalysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Dividend)
			.map((x) => x.analysisID.toString());
		return this.buildDividendAnalysisSettingValue(dividendAnalysis, dividendAnalysisSubSetting, true, AccrualBasis.Accrual);
	};

	buildWashSalesAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const washSalesAnalysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.WashSales,
		);
		const washSalesAnalysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.WashSales)
			.map((x) => x.analysisID.toString());
		return this.buildWashSalesAnalysisSettingValue(washSalesAnalysis, washSalesAnalysisSubSetting, false);
	};

	buildPaymentsAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const allPaymentsAnalysis = analysisSettingsResponse.analysis
			.filter((x) => x.analysisGroup === AnalysisGroup.Payments)
			.map((x) => x.analysisID.toString());
		return this.buildPaymentAnalysisSettingValue(allPaymentsAnalysis);
	};

	buildStraddlesAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Straddles,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Straddles)
			.map((x) => x.analysisID.toString());
		return this.buildStraddlesAnalysisSettingValue(
			analysis,
			analysisSubSetting,
			Hedging.OneToAll,
			ReliefMethodOptions.FIFO,
			ReliefMethodOptions.FIFO,
		);
	};

	buildConstructiveSalesAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.ConstructiveSales,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.ConstructiveSales)
			.map((x) => x.analysisID.toString());
		return this.buildConstructiveSalesAnalysisSettingValue(
			analysis,
			analysisSubSetting,
			ReliefMethodOptions.FIFO,
			ForcedConstructiveSales.Yes,
		);
	};

	buildCharacterAnalysis = (analysisSettingsResponse: AnalysisSettingsResponse) => {
		const analysisSubSetting = analysisSettingsResponse.analysisSettings.filter(
			(item) => item.analysisGroup === AnalysisGroup.Character,
		);
		const analysis = analysisSettingsResponse.analysis
			.filter((item) => item.analysisGroup === AnalysisGroup.Character)
			.map((x) => x.analysisID.toString());
		return this.buildCharacterAnalysisSettingValue(
			analysis,
			analysisSubSetting,
			Character.SixtyFortySplit
		);
	};

	buildStartDate = (): Date => {
		const priorYear = new Date().getFullYear() - 1;
		return new Date(priorYear, 0, 1);
	};

	buildEndDate = (): Date => {
		const priorYear = new Date().getFullYear() - 1;
		return new Date(priorYear, 11, 31);
	};

	buildClientType = (): ClientType => {
		return ClientType.Investor;
	};
	buildReporting = (): Reporting => {
		return Reporting.Monthly;
	};
	buildBifurcation = (): Bifurcation => {
		return Bifurcation.Upload;
	};
}
